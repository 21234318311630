$sidebar-dark-nav-dropdown-bg: #1B5C99;
$sidebar-dark-nav-link-hover-bg: #288BE5;
$sidebar-dark-nav-link-active-bg:#288BE5;
$sidebar-dark-nav-link-active-icon-color:'red';
// $sidebar-nav-link-padding-x: 0;
$sidebar-theme-map: (

);
$sidebar-width: 280px;  //17.5rem

html:not([dir=rtl]) .c-sidebar-nav-dropdown-items {
  .c-sidebar-nav-item .c-sidebar-nav-link{
    border-radius: 6px;
    padding: 8px 10px;
    margin: 8px 20px 8px 50px;

  }
}
html:not([dir=rtl]) .c-sidebar-nav-icon:first-child{
  margin-right: -0.5rem;
}
.c-sidebar .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle {
  .c-sidebar-nav-icon{
    color: #F1F1F1;    
  }
}
.c-sidebar .c-sidebar-nav-item .c-sidebar-nav-link {
  .c-sidebar-nav-icon{
    color: #F1F1F1;    
  }
}
.c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle::after {
  flex-basis: 14px;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  .c-sidebar-nav-icon{
    // color: #2D9AFF;
    color: #fff;
    font-weight: 700;
  }
  .c-sidebar-nav-dropdown-toggle{
    font-weight: 700;
  }
  // .c-sidebar-nav-dropdown-items{
  //   .c-active{
  //     font-weight: 700;      
  //   }
  // }
}
.c-active{
  font-weight: 700;
}

html:not([dir=rtl]) .c-sidebar {
  .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-toggle::after {
    background-image: url(../assets/icons/arrow-down.png);
    
  } 
  .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
} 

}
.c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle {
  gap: 0.6rem
}