// Here you can add other styles
._loading_overlay_overlay {
  z-index: 9999 !important;
  background: rgb(40 40 40 / 50%) !important;
}

.form-group.required .control-label:before {
  content: '*';
  color: red;
}

.lb-upload {
  cursor: pointer;
  color: #26b7d2;
  margin: 5px 8px;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.cv-button-upload {
  width: 100%;
  max-width: 100%;
}

.cv-bt-left {
  float: left;
}

.bt-remove-img {
  float: right;
  margin: 5px 8px;
  color: #ff030a;
  cursor: pointer;
}

// .cke_contents {
//   /*height: 120px !important;*/
// }

@media (max-width: 576px) {
  .flex-column-reverse-sx {
    flex-direction: column-reverse;
  }
}

.filter-gift .py-3 {
  padding-left: 5px;
  padding-right: 5px;
}

.filter-gift .inp-point-filter {
  float: left;
  width: 45%;
}

.filter-gift .slash-from-to {
  float: left;
  font-size: 25px;
  margin: 0 2px;
}

.filter-gift .custom-inp-filter {
  max-width: 12%;
  flex: 0 0 12%;
}

.filter-gift .custom-inp-filter-end {
  max-width: 23.3%;
  -webkit-flex: 0 0 23.3%;
  flex: 0 0 23.3%;
}

.cv-btn-gift .btn {
  width: 90px;
  margin: 10px 0 10px 10px;
}

.switch-status {
  float: left;
  margin-top: 15px;
}

.lb-switch-status {
  float: left;
  padding: 18px 5px;
}

.cv-btn-gift {
  float: right;
  margin: 0;
}
.logo-thumbnail {
  background: #ccc;
  text-align: center;
  line-height: 250px;
  font-size: 35px;
  font-weight: 500;
  width: 80%;
  display: inline-block;
  .upload-image & {
    width: 400px;
    line-height: 200px;
  }
}
.thumbnail-merchant {
  max-width: 65%;
  img {
    max-width: 100%;
    min-height: 100px;
  }
}
.recommend-thumbnail {
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: contain;
  float: left;
  background: #ccc;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 35px;
  font-weight: 500;
}

.recommend-thumbnail-gift {
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: contain;
  float: left;
  background: #ccc;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 35px;
  font-weight: 500;
  max-width: 100%;
}

.cv-end-date-inp .react-datepicker-wrapper {
  float: right;
}

.thumb-gift-item {
  width: 100px;
}

.cv-action-icon a {
  margin: 4px;
}

.cv-action-icon .fa {
  font-size: 17px;
}

.ul-list-item {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 17px;
}

.ul-list-item li {
  margin: 7px 0;
}

.first-col {
  border-right: 1px solid #ccc;
}

/*style for keyword input*/

.ReactTags__tags {
  position: relative;
  border: 1px solid #ccc;
  padding: 10px 2px;
}

/* Styles for the input */

.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
  margin-top: 3px;
  margin-left: 5px;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: 1px solid #eee;
}

/* Styles for selected tags */

.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 3px 5px;
  border-radius: 2px;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */

.ReactTags__suggestions {
  position: absolute;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
}

.disable-action {
  opacity: 0.3;
}

/*end style for keyword input*/

.filter-point {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

._loading_overlay_content {
  margin-top: 50vh !important;
}

.content-settings-cbutton-height {
  height: 40px;
}

.rc-time-picker-input {
  height: 35px !important;
}

.rc-time-picker-clear {
  top: 7px !important;
}

.mission-question-add {
  flex: 1;
}

.mission-question-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: black 1px solid;
  margin-top: 6px;
  margin-right: 10px;
}

.mission-question-background {
  background-color: #d7d5d5;
  color: black;
  border-radius: 15px;
  padding: 20px;
}

.mission-question-square {
  width: 10px;
  height: 10px;
  border: black 1px solid;
  margin-top: 6px;
  margin-right: 10px;
}

.mission-break-line {
  width: 100%;
  border: 2px solid #a4a4a4;
  margin-left: -1px;
}

.mission-line-height {
  margin-left: 15px;
  margin-right: 15px;
  height: 100%;
  border-left: 2px solid #a4a4a4;
}

.mission-swtich-status .react-switch-bg {
  height: 20px !important;
}

.mission-swtich-status .react-switch-bg :first-child {
  height: 20px !important;
}

.mission-swtich-status .react-switch-handle {
  height: 18px !important;
  width: 20px !important;
}

.mission-text-underline {
  text-decoration-line: underline;
  text-underline-offset: 2px;
}

.mission-border-dotted {
  border-bottom: 1px dotted black;
}

.mission-button-center {
  margin-top: 10px;
}

#mission-upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.mission-lb-upload {
  cursor: pointer;
  color: #26b7d2;
  margin: 5px 8px;
}

.mission-btn .btn {
  width: 110px;
  margin: 10px 0 10px 10px;
}

.cv-btn-center {
  width: 100%;
  text-align: center;
}

.cv-btn-center .btn {
  margin: 0 5px;
}

.cv-image-padding {
  padding: 0 12%;
}

.mission-remove-question .fa {
  transform: scale(1.7, 1.7);
}

.modal.mission-index-answer {
  z-index: 1055;
}
.modal.mission-index-answer ~ .modal-backdrop {
  z-index: 1051;
}

.modal.mission-index-question {
  z-index: 1050;
}

.thumbnail-item {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.thumbnail-item-gift {
  aspect-ratio: 5 / 4;
  max-width: 100%;
  width: 100%;
}

.thumbnail-item-ticket {
  display: 'inline-block';
  width: '100%';
}

.thumbnail-item-ticket-create {
  aspect-ratio: 5/4;
  width: 15%;
  border-style: solid;
  border-color: gray;
}

.remove-item-ticket-create {
  width: 15%;
  text-align: center;
}

.thumbnail-create {
  width: 15%;
  height: 15%;
  position: relative;
  display: inline;

  }

  .thumbnail-create img {
  width: 15%;
  aspect-ratio: 5/4;
  margin: 2% 2% 0% 0%;
  }

  .btn-remove-ticket-img {
    display: block;
    width: 25px;
    height: 25px;
    font-size: 100%;
    position: absolute;
    bottom: 76px;
    right: 38px;
    text-align: center;
    overflow: hidden;
  }

  .btn-remove-ticket-img-edit {
    display: block;
    width: 25px;
    height: 25px;
    font-size: 100%;
    position: absolute;
    top: 0px;
    right: 15px;
    text-align: center;
    overflow: hidden;
  }

.lb-upload-video {
  background: #39f;
  color: #fff;
  padding: 7px;
  cursor: pointer;
  border-radius: 4px;
}

#upload-video {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.cls-hide {
  display: none;
}

.cls-show {
  display: block;
}

.cv-mobile-review {
  width: 100%;
  float: left;
  height: 440px;
  text-align: center;
  position: relative;
}

.cv-mobile-review .preview-bg {
  width: 250px;
  height: 440px;
}

.cv-mobile-review .preview-content {
  position: relative;
  width: 223px;
  padding: 0 5px 0 7px;
  margin: -390px auto;
  height: 307px;
  overflow-y: scroll;
}

.cv-mobile-review .preview-content img {
  width: 100% !important;
}

.cv-mobile-review .preview-content .preview-title {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
  line-height: 19px;
}

.cv-mobile-review .preview-content .preview-img {
  width: 100%;
  border-radius: 5px;
}

.cv-mobile-review .preview-content .preview-time-share {
  width: 100%;
  margin: 5px 0;
}

.cv-mobile-review .preview-content .cover-content-preview {
  width: 100%;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.link-to {
  margin-top: 25px;
  display: inline-block;
  text-decoration: underline;
}

.status {
  margin-top: 10px;
  span {
    padding-left: 15px;
    &.status-item {
      color: red;
      font-weight: 500;
    }
  }
}

.mission-time-picker .rc-time-picker-clear {
  display: none;
}

._loading_overlay_spinner {
  position: fixed !important;
}

/* width */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mission-group-correct {
  display: flex;
  margin-top: 15px;
}

.mission-correct {
  margin-top: 40px;
  margin-left: 15px !important;
}

.mission-time-picker {
  width: 100%;
  height: 45px;
}

.mission-date-picker .react-datepicker-wrapper {
  width: 100% !important;
}

.lb-switch-status-gift {
  padding-top: 3px;
  display: block;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1003;
  background: #000000;
  overflow: hidden;
}
.spinner div:first-child {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  box-shadow: 0 3px 3px 0 rgba(255, 56, 106, 1);
  transform: translate3d(0, 0, 0);
  animation: spin 2s linear infinite;
}
.spinner div:first-child:after,
.spinner div:first-child:before {
  content: '';
  position: absolute;
  border-radius: 50%;
}
.spinner div:first-child:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  box-shadow: 0 3px 3px 0 rgb(255, 228, 32);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.spinner div:first-child:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  box-shadow: 0 3px 3px 0 rgba(61, 175, 255, 1);
  animation: spin 1.5s linear infinite;
}

.custom-filter-time {
  height: 40px;
  width: 100%;
  border: 1px solid #4285f4;
  border-radius: 4px;
  box-shadow: #4285f4 0 0 0 1px;
  .time-heading {
    position: relative;
    padding: 0 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    cursor: default;
    outline: 0;
    .time-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
    .clear-selected-button {
      cursor: pointer;
      background: none;
      border: 0;
      padding: 0;
      display: flex;
    }
    .gray {
      color: #aaa;
    }
  }
  .custom-popup-time {
    position: relative;
    margin-top: 6px;
    height: auto;
    width: 235px;
    z-index: 100;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 20px;
  }
}

.custom-filter-time-unclicked {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 0 0;
}

.custom-filter-time-quick {
  width: 93%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 10px;
  text-align: center;
  line-height: 35px;
}

.custom-filter-time-quick-bg {
  background-color: #4285f4;
}

.custom-middle-items {
  display: flex;
  justify-content: center;
}

.mobile-screen-preview {
  width: 340px;
  height: 544px;
}

.recommend-notification {
  aspect-ratio: 2 / 1;
  width: 100%;
  object-fit: contain;
  float: left;
  background: #ccc;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 35px;
  font-weight: 500;
}

.notification-item {
  aspect-ratio: 2 / 1;
  width: 100%;
  object-fit: contain;
}
.reward-suffix {
  font-weight: 600;
  padding: 5px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #ccc;
}
.custom-gift-store-btn {
  min-width: 150px;
}
.card-body-min-height {
  min-height: 132px;
}
.upper-input {
  text-transform: capitalize;
}
.margin-left-5 {
  margin-left: 5px;
}

.text-container {
  width: auto;
  display: block;
  float: left;
}

.text-left{
  width: 200px;
  display: block;
  float: left;
  text-align: left;
}

.text-right{
  width: 200px;
  display: block;
  float: right;
  text-align: right;
}

.datePickerCustom {
  width: 100%;
}

.thumbnail-image-bill-upload {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.input-form-select-tree-custom .input-select-tree-address .dropdown {
  width: 100%;
}

.input-form-select-tree-custom .input-select-tree-address .dropdown .dropdown-trigger {
  width: 100%;
  min-height: 40px;
  border-radius: 4px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow:after {
  float: right;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component {
  height: 250px !important;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component
  .all-item {
  padding-left: 13px !important;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component
  .all-item
  i {
  display: none !important;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component
  .provinces-item {
  padding-left: 0px !important;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component
  .district-item {
  padding-left: 20px !important;
}



.custom-notification-user {
  position: fixed;
  display: flex;
  right: 0;
  z-index: 1024;
  top: 55px;
  width: 700px;
}

.Collapsible {
  background-color: white;
  padding-top: 15px;
}
.Collapsible__contentOuter {
  // border-bottom: 1px solid #d2d2d2;
}
.Collapsible__contentInner {
  padding: 0px 0px 24px 40px;
  // border: 1px solid #ebebeb;
  border-top: 0;
  font-size: 12px;
}
.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
}
.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}
.Collapsible__trigger {
  display: block;
  text-decoration: none;
  position: relative;
  padding: 0px 15px 15px 0px;
  font-size: 12px;
}
.Collapsible__trigger:after {
  font-family: 'FontAwesome';
  color: #000;
  content: '\f107';
  position: absolute;
  left: 15px;
  top: -2px;
  display: block;
  font-size: 16px;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}

.Collapsible__trigger.is-open {
  padding-left: 40px;
}
.Collapsible__trigger.is-closed{
  padding-left: 40px;
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

// .report-card-recall-detail  {
//   border: 1px solid red;
//   height: calc(100vh - 200px)  !important;
//   overflow-y: auto !important;
// }

.list-gifts-on-demand .rdt_TableBody {
  height: calc(100vh - 418px)  !important;
  overflow:  hidden auto;
}
.gift-card-recovery .rdt_TableBody {
  height: calc(100vh - 410px)  !important;
  overflow:  hidden auto;
}
.tracking-status-order .rdt_TableBody{
  height: calc(100vh - 310px)  !important;
  overflow:  hidden auto;
}
.report-card-recall-detail .rdt_TableBody {
  height: calc(100vh - 367px)  !important;
  overflow:  hidden auto;

}
.rdt_Pagination {
  min-height: 38px !important;
}
.c-main{
  padding-top: 10px !important
}
.table-fixed-header {
  display: flex;
  justify-content: space-between;
  margin-right: 18px
}
.report-card-recall {
  height: calc(100vh - 254px)  !important;
  overflow: auto !important;
}
.report-card-recall::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.rdt_TableBody::-webkit-scrollbar {
  width: 10px;
}
// .sc-hIqOWS::-webkit-scrollbar {
//   height: 10px;
// }
.fixTableHead thead {
  position: sticky;
  top: 0;
}
.fixTableHead th, td {
  padding: 5px 10px;
  font-size: 13px;
}
.fixTableHead th {
  background: #fff;
}
.bg-remove-on-ap{
  background-color: #888d90 !important;
}
.fixTableHeadReport thead {
  position: sticky;
  top: 0;
}
.fixTableHeadReport th, td {
  padding: 0px 10px;
  font-size: 13px;
}
.report-change-gift-total {
  height: calc(100vh - 255px)  !important;
  overflow: auto !important;
}

.report-sum-gift-nvkd {
  height: calc(100vh - 255px)  !important;
  overflow: auto !important;
}
table {
  width: 100%;
}

// .report-gift-ntd {
//   height: calc(100vh - 255px)  !important;
//   overflow: auto !important;
// }
.report-gift-ntd .rdt_TableBody{
  height: calc(100vh - 368px)  !important;
  overflow:  hidden auto;
}
.report-change-gift .rdt_TableBody{
  height: calc(100vh - 370px)  !important;
  overflow:  hidden auto;
}
.custom-div-external-ticket {
  max-height: 40vh;
  overflow: auto;
}
.custom-input-date-time-picker {
  height: 40px !important;
}

.vieon-dashboard-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.vieon-dashboard-responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.link-hover:hover{
  background-color: #009BFF;
}

.link-hover:hover a i span{
  color: white;
}

.custom-notification-attachment-review {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px #908b8b;
}

.cv-btn-notification .btn {
  width: 150px;
  margin: 10px 0 10px 10px;
}

.cv-btn-notification {
  display: flex;
  justify-content: flex-end;
}

.customCol.col-xxxl-6 {
  @media (min-width: 1500px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.customCol.mt-xxxl-0 {
  @media (min-width: 1500px) {
    margin-top: 0 !important;
  }
}

.react-datepicker-popper.custom-date-picker-user-management {
  z-index: 10;
}

.custom-label-user-management {
  width: 90px;
}

.label-link {
  cursor: pointer;
  color: #26b7d2;
  margin: 10px;
}

.label-link:hover,
.add-gift-enable:hover {
  text-decoration: underline;
}

@media (min-width: 1800px) {
  .custom-col.col-xxxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.form-group.required .control-label-after::after {
  content: '*';
  color: red;
}

.custom-multiselect .dropdown-container[aria-disabled='true'] {
  background-color: rgb(242, 242, 242);
  pointer-events: none;
}

.preview-template-sms {
  position: relative;
  background-color: #ececec;
  border-radius: 16px;
  padding: 16px 16px 20px;
  width: 250px;
  max-width: 100%;
}

.preview-template-sms::after {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #ececec;
  position: absolute;
  bottom: -6px;
  left: 16px;
  transform: rotate(60deg);
}

.preview-template-zns {
  // background-color: #fff;
  // border-radius: 4px;
  // border: 1px solid #ececec;
  padding-bottom: 10px;
}

.preview-template-zns::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
}

.preview-template-zns::-webkit-scrollbar-track {
  border-radius: 25px;
  background-color: #f1f1f1;
}

.preview-template-zns::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 25px;
}

.preview-zns-title {
  font-weight: 700;
  margin-bottom: 1rem;
}
.preview-zns-content {
  min-height: 50px;
}

.preview-zns-cta-1 {
  color: #fff;
  background-color: #0084ff;
  text-align: center;
  padding: 4px;
  margin-bottom: 8px;
  min-height: 25px;
}

.preview-zns-cta-2 {
  color: #0059c3;
  background-color: #d5ebff;
  text-align: center;
  padding: 4px;
  min-height: 25px;
}

.params-suggestion-block .dropdown-toggle::after {
  display: none;
}

.preview-template-collapse-block {
  padding: 8px;
  margin: 16px auto;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0.154px 0.462px 0px rgba(0, 0, 0, 0.1),
    0px 0.821px 1.847px 0px rgba(0, 0, 0, 0.13);

  display: flex;
  gap: 8px;
  width: 280px;
  max-width: 100%;
}

.preview-template-logo-img {
  max-width: 40px;
  aspect-ratio: 1 / 1;
  border-radius: 9999px;
}

.preview-template-collapse-title {
  padding-right: 4px;
  font-weight: 700;
  min-height: 20px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.preview-template-collapse-content {
  max-width: 180px;

  *:first-child {
    padding-right: 4px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
  }

  *:not(:first-child) {
    display: none;
  }
}

.preview-template-detail-block {
  background-image: url(../../public/assets/img/phone_frame.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  position: relative;
  height: 580px;
  width: 300px;
  max-width: 100%;
  margin: 16px auto;
}

.preview-template-detail-description-block {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.preview-template-detail-description {
  width: 90%;
  height: 90%;
  overflow: hidden;
  margin: 57px auto 0px;
  padding: 0px 16px 60px;

  position: relative;
}

.preview-template-detail-content {
  padding: 4px;
  max-height: 200px;
  overflow: auto;
  font-size: 13px;
}

.preview-template-detail-btns-block {
  position: absolute;
  left: 0;
  top: 85%;

  padding: 8px 24px;
  width: 100%;

  @media (min-width: 1500px) and (max-width: 1550px) {
    top: 80%;
  }

  @media (min-width: 1400px) and (max-width: 1500px) {
    top: 70%;
  }
}

.preview-template-1-cta {
  color: #fff;
  background-color: #0084ff;
  border: 1px solid #0084ff;
  text-align: center;
  padding: 4px;
  margin-bottom: 8px;
  min-height: 25px;
  border-radius: 4px;
  width: 100%;
}

.preview-template-2-ctas {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.preview-template-2-cta-1 {
  color: #0084ff;
  background-color: #fff;
  border: 1px solid #0084ff;
  text-align: center;
  padding: 4px;
  min-height: 25px;
  border-radius: 4px;
  overflow: hidden;
}

.preview-template-2-cta-2 {
  color: #fff;
  background-color: #0084ff;
  border: 1px solid #0084ff;
  text-align: center;
  padding: 4px;
  min-height: 25px;
  border-radius: 4px;
  overflow: hidden;
}

.preview-template-banner-img {
  width: 100%;
  height: 100px;
  margin: 8px auto 0;
}
.preview-template-banner-block {
  margin: 8px auto 0;
  background-color: #ccc;
  width: 100%;
  height: 100px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interaction-banner-img-block {
  background-color: #ccc;
  width: 50%;
  height: 120px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-template-attach-references {
  font-size: 14px;
  margin-top: 8px;
}

.preview-template-content-block {
  max-height: 85%;
  overflow: auto;
  padding-right: 8px;
}

.custom-preview-auto-interaction {
  .preview-template-detail-btns-block {
    @media (min-width: 1500px) and (max-width: 1550px) {
      top: 85%;
    }

    @media (min-width: 1400px) and (max-width: 1500px) {
      top: 85%;
    }
  }
}

.custom-popup-time-picker .rc-time-picker-panel-combobox {
  display: flex;
}

.fullHeight-datepicker {
  height: 100%;
  .react-datepicker__input-container {
    height: 100%;
  }
  input {
    height: 100%;
  }
}
